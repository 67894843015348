import { css } from '@emotion/core';
import variables from 'Theme/styles/vars';

export default (props, state) =>
    css`
        min-height: 90vh;
        position: relative;
        background-color: ${variables.dpc_backgroundWhite};

        .header {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            background-color: #101010;
            padding: 1.2em 0;

            .logo {
                width: 100%;
                max-width: 14em;
                margin: 0 auto;
            }
        }

        .content {
            .body {
                margin-top: 5em;

                .section {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    flex-direction: column;
                    color: ${variables.dpc_fontDark};
                    
                    &.introduction {    
                        margin-bottom: 2em;

                        .headline {
                            font-size: 2em;
                            font-weight: 800;
                        }

                        .subheadline {
                            margin-top: .5em;
                            font-size: 1em;
                            text-align: center;
                            width: 50%;
                            margin-top: 2em;
                        }
                    }

                    &.about {
                        margin-bottom: 4em;
                        flex-direction: row;
                        align-items: flex-start;

                        .right {
                            .info-boxes-container {
                                display: flex;

                                .box {
                                    width: 15em;
                                    flex-direction: column;
                                    justify-content: flex-start;
                                    align-items: center;
                                    padding: 1em;
                                    border: 1px solid red;

                                    .title {
                                        text-align: center;
                                        margin-top: 1em;
                                    }
                                    
                                    .icon-container {
                                        width: 50%;
                                        height: 50%;

                                        svg {
                                            width: 100%;
                                            height: 100%;
                                        }
                                    }

                                    &:not(:last-of-type) {
                                        margin-right: 2em;
                                    }
                                }
                            }
                        }
                    }
                    
                    &.cta {
                        margin-top: 1em;
                        margin-bottom: 1em;
                    }

                    &.specific-information {
                        text-align: left;
                        margin-top: 2em;
                    }
                }
            }
        }

        @media (max-width: ${variables.tabletL}) {
            .content {
                .body {
                    .section {
                        &.introduction {    
                            .headline {
                                font-size: 2em;
                                font-weight: 800;
                                text-align: center;
                            }

                            .subheadline {
                                width: 80%;
                            }
                        }
                    }
                }
            }
        }

        @media (max-width: ${variables.mobileL}) {
            .content {
                .body {
                    .section {
                        &.introduction {    
                            .headline {
                                font-size: 2em;
                                font-weight: 800;
                                text-align: center;
                            }

                            .subheadline {
                                width: 80%;
                            }
                        }
                    }
                }
            }
        }
    `;

